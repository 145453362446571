import { brandConfig } from '@hubcms/brand';
import { Labels } from '@hubcms/ui-taxonomy';

import type { StoryLabelsProps } from './types';

function StoryLabels({ label, sublabel, isPremium, ...labelProps }: StoryLabelsProps) {
  return (
    <Labels
      label={label}
      sublabel={sublabel}
      isPremium={isPremium ?? false}
      showPremiumIcon={brandConfig.articleLayoutConfig.heroblockPremiumIconLocation === 'label'}
      data-testid="article-label"
      {...labelProps}
    />
  );
}

export default StoryLabels;
