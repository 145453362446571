import { Heading, Logo } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { brandConfig } from '@hubcms/brand';
import { annotate } from '@hubcms/utils-story-elements';

import styles from './story-headline.module.scss';
import type { StoryHeadlineProps } from './types';

function StoryHeadline({
  className,
  text,
  annotations,
  isPremium = false,
  level = 1,
  id,
  size,
  onDoubleClick,
}: StoryHeadlineProps) {
  return (
    <div className={styles.storyHeadline}>
      <Heading
        data-testid="article-headline"
        className={cx(className, styles.storyHeadlineHeading)}
        color="var(--x-story-headline-color, var(--story-headline-color, inherit))"
        level={level}
        id={id}
        size={size}
        onDoubleClick={onDoubleClick}
      >
        {brandConfig.articleLayoutConfig.heroblockPremiumIconLocation === 'heading' && isPremium && (
          <Logo name="logo-premium-main" className={cx(styles.premiumIcon)} />
        )}
        {annotate(text, annotations)}
      </Heading>
    </div>
  );
}

export default StoryHeadline;
